import React, {FC, useEffect, useMemo, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Divider } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';

import Modal from "../Layout/Modal";
import Row from "../Layout/Row";
import Col from "../Layout/Col";
import TextField from "../Fields/TextField";
import DateTimePickerField from "../Fields/DateTimePickerField";
import DatePickerField from "../Fields/DatePickerField";
import NumberField from "../Fields/NumberField";
import ButtonField from "../Fields/ButtonField";
import CheckboxField from "../Fields/CheckboxField";
import ResourcesEditTable from "./ResourcesEditTable";
// import PositionsEditTable from "./PositionsEditTable";
import { getPickedTemplate, getSelectedEvent } from "../../redux/selectors/calendarSelectors";
import {
	setCreateEventMode,
	setNewEvent,
	setSelectedEvent as setSelectedEventRedux, setSelectedEventIdForPlanning,
	setSelectedTemplateId,
	setRefreshEvents
} from "../../redux/actions/calendarActions";
import {addEvent, updateEvent} from "../../redux/actions/eventsActions";
import {
	createEventFromAPI,
	updateEventFromAPI,
	getEventResourcesOptionsFromAPI,
	createBillFromExternalAPI,
	sendEmailFromExternalAPI,
	unlockEventFromExternalAPI,
	lockEventAndGetInfoFromExternalAPI,
	getBillLinkFromExternalAPI,
	createTemplateFromAPI,
	getFilterOptionsFromAPI,
	createEventSeriesFromAPI
} from "../../helpers/api";
import {ResourceGroupsDataType} from "../../helpers/dataProcessor";
import {CalendarEventType} from "../Calendar/CalendarEventRow";
import {getStoreManagerURL} from "../../redux/selectors/appConfigSelectors";
import { eventMovable } from "../../helpers/calendarHelpers";
import { toast } from "react-toastify";
import { getUser, getUserId } from "../../redux/selectors/userSelectors";
import { setFilterOptions } from "../../redux/actions/filterOptionsActions";
import SelectField from "../Fields/SelectField";

interface SmEventResponseType {
	Type: string;
	Status: string;
	UserNr: string;
	OrderNr: string;
	OldUserNr: string;
	OldStatus: string;
}

export interface SeriesOptionsType {
	wholeDay: boolean;
	fromDate: Date;
	toDate: Date;
	seriesType: "week" | "month";
	interval: number;
	days: number[];
}

const EventEditTable: FC = () => {
	const dispatch = useDispatch();
	const selectedEventRedux = useSelector(getSelectedEvent);
	const storeManagerURL = useSelector(getStoreManagerURL);
	const userId = useSelector(getUserId);
	const user = useSelector(getUser);
	const pickedTemplate = useSelector(getPickedTemplate);
	const { t } = useTranslation();
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [selectedEvent, setSelectedEvent] = useState<CalendarEventType | null>(null);
	const [isVirtual, setIsVirtual] = useState(false);
	const [resourceGroups, setResourceGroups] = useState<ResourceGroupsDataType>({});
	const [apiEventNotEditable, setApiEventNotEditable] = useState(false);
	const [editMsg, setEditMsg] = useState('');
	const [smEventResponse, setSmEventResponse] = useState<SmEventResponseType | undefined>(undefined);
	const [seriesModalOpened, setSeriesModalOpened] = useState(false);
	const [seriesOptions, setSeriesOptions] = useState<SeriesOptionsType>({
		wholeDay: false,
		fromDate: new Date(),
		toDate: new Date(),
		seriesType: "week",
		interval: 1,
		days: [1, 2, 3, 4, 5, 6, 7],
	});

	const eventLocked = (event: CalendarEventType, eventResp: SmEventResponseType) => {
		if (!event) return false;
		// User with read only access
		if (!user.facturabill_modify) {
			console.log('eventLocked: !user.facturabill_modify', user);
			return true;
		}
		// Public event editable
		if (event.contingented) return false;

		// Event is live
		if (eventResp?.Type !== 'temp') {
			if (['0', '1', '2'].includes(eventResp.Status)) {
				console.log('eventLocked 1');
				setEditMsg(t('order_is_in_archive'));
				return false;
			}
			if (['3', '4', '11', '15'].includes(eventResp.Status)) {
				console.log('eventLocked 2');
				setEditMsg(t('selected_event_is_already_booked'));
				return !window.confirm(t('do_you_want_to_change_the_current_event'))
			};
		} else {
			// Event in temp table
			if (Number(userId) !== Number(eventResp?.UserNr)) {
				console.log('eventLocked 3');
				setEditMsg(t('event_is_locked_by_another_user'));
				return true;
			} else {
				console.log('eventLocked 4');
				setEditMsg(t('changes_in_the_event_may_affect_changes_in_the_order'));
				return false;
			}
		}
		
		console.log('eventLocked 5');
		setEditMsg('');
		return false;
	}

	useEffect(() => {
		if (!selectedEventRedux) return;
		getEventResourcesOptionsFromAPI(selectedEventRedux?.originalId)
			.then((groups) => {
				console.log(groups);
				setResourceGroups(groups);
			})
			.catch((err) => {
				console.log(err);
			});
		
		if (selectedEventRedux?.status === '5') {
			setSmEventResponse(undefined);
			return;
		};
		lockEventAndGetInfoFromExternalAPI(storeManagerURL, selectedEventRedux?.originalId)
		.then((resp) => {
			console.log(resp);
			setSmEventResponse(resp.data);
			if (eventLocked(selectedEventRedux, resp.data)) {
				setApiEventNotEditable(true);
			} else {
				setApiEventNotEditable(false);
			}
		})
		.catch((err) => {
			console.log(err);
		});
	}, [selectedEventRedux?.originalId]);

	useEffect(() => {
		setSelectedEvent(selectedEventRedux);

		if (selectedEventRedux && selectedEventRedux.id === 'virtual') {
			setIsVirtual(true);
			console.log('Virtual event modal');
		} else {
			setIsVirtual(false);
		}

		setEditMsg('');
	}, [selectedEventRedux]);

	useEffect(() => {
		// unlock event on tab close
		window.addEventListener('beforeunload', onBeforeUload, { capture: true });
		console.log('beforeunload event listener added');

		return () => {
			console.log('beforeunload event listener removed');
			window.removeEventListener('beforeunload', onBeforeUload, { capture: true });
		}
	}, [selectedEventRedux?.originalId]);

	const onBeforeUload = (e: BeforeUnloadEvent) => {
		e.preventDefault();

		if (smEventResponse && selectedEventRedux && selectedEventRedux?.status !== '5') {
			unlockEventFromExternalAPI(storeManagerURL, selectedEventRedux?.originalId, '1', '1')
			.then((resp) => {
				console.log(resp);
			})
			.catch((err) => {
				console.log(err);
			});
		}

		e.returnValue = 'Are you sure?';
		return 'Are you sure?';
	};

    const handleCancel = async () => {
		if (smEventResponse && selectedEventRedux && selectedEventRedux?.status !== '5') {
			unlockEventFromExternalAPI(storeManagerURL, selectedEventRedux?.originalId, smEventResponse.OldStatus, smEventResponse.OldUserNr)
			.then((resp) => {
				console.log(resp);
			})
			.catch((err) => {
				console.log(err);
			});
		}

        dispatch(setSelectedEventRedux(null));
    };

	const handleSaveDraft = () => {
		if (!selectedEventRedux || !selectedEvent) return;
		dispatch(setNewEvent({...selectedEvent, locked: true}));
		dispatch(updateEvent({...selectedEvent, locked: true}));
		dispatch(setSelectedEventRedux(null));
	};

	const handleCreateTemplate = async () => {
		try {
			if (!selectedEvent || !pickedTemplate) return;

			await createTemplateFromAPI(
				selectedEvent,
				selectedEvent.sourceTemplateName!,
				pickedTemplate.hostNr,
				pickedTemplate.branchNr,
				pickedTemplate.billNr,
			);
			toast.success('Template was created');
			getFilterOptionsFromAPI()
			.then((filtersOpts) => {dispatch(setFilterOptions(filtersOpts))});
			dispatch(setSelectedEventRedux(null));
			dispatch(setSelectedTemplateId(null));
			dispatch(setCreateEventMode(false));
		} catch (e) {
			toast.error('Template could not be created');
			console.error(e);
			setButtonDisabled(false);
		}
	};

	const handleCreate = async () => {
		if (!selectedEventRedux || !selectedEvent) return;
		setButtonDisabled(true);

		if(selectedEvent.asNewTemplate) {
			handleCreateTemplate();
			return;
		}

		try {
			const events = await createEventFromAPI(selectedEvent);
			// alert('Event created');
			toast.success('Event created');
			const event = events[0];
			if (event && event.id && selectedEvent.templateId) {
				createBillFromExternalAPI(storeManagerURL, event.id, selectedEvent.templateId)
				// createBillFromExternalAPI('19769', '7')
					.then((response) => {
						console.log(response);
						if (response.status === 0 && typeof response.data === "object" && response.data.gotoaddress !== '') {
							// window.open(response.data.gotoaddress, '_blank')?.focus();
							// window.location.href = response.data.gotoaddress;
							const popup = window.open(response.data.gotoaddress, 'Factura','height=1000,width=1200');
							popup?.focus();
						} else {
							console.log('No redirect required');
						}
					})
					.catch((e) => console.log(e))
					.finally(() => {
						dispatch(addEvent(selectedEventRedux));
						dispatch(setSelectedEventRedux(null));
						dispatch(setSelectedTemplateId(null));
						dispatch(setCreateEventMode(false));
					});
			}
		} catch (e) {
			// alert('Event could not be created');
			toast.error('Event could not be created');
			console.error(e);
		} finally {
			setButtonDisabled(false);
		}
	};

    const handleSave = async () => {
    	if (!selectedEventRedux || !selectedEvent) return;
		setButtonDisabled(true);

        try {
			await updateEventFromAPI(selectedEvent);
			if (smEventResponse && selectedEventRedux && selectedEventRedux?.status !== '5') {
				unlockEventFromExternalAPI(storeManagerURL, selectedEventRedux?.originalId, smEventResponse.OldStatus, smEventResponse.OldUserNr)
				.then((resp) => {
					console.log(resp);
				})
				.catch((err) => {
					console.log(err);
				});
			}
			await sendEmailFromExternalAPI(storeManagerURL, selectedEvent.id);
			if (selectedEvent.sourceTemplateId && !selectedEvent.orderLink) {
				createBillFromExternalAPI(storeManagerURL, selectedEvent.id, selectedEvent.sourceTemplateId)
					.then((response) => {
						console.log(response);
						if (response.status === 0 && typeof response.data === "object" && response.data.gotoaddress !== '') {
							// window.open(response.data.gotoaddress, '_blank')?.focus();
							// window.location.href = response.data.gotoaddress;
							const popup = window.open(response.data.gotoaddress, 'Factura','height=1000,width=1200');
							popup?.focus();
						} else {
							console.log('No redirect required');
						}
					})
					.catch((e) => console.log(e));
			}
			// console.log('Opener', window.opener);
			// window.opener?.location?.reload();
			// alert('Event updated');
			toast.success('Event updated');
			dispatch(updateEvent(selectedEvent));
			dispatch(setSelectedEventRedux(null));
			dispatch(setSelectedEventIdForPlanning(null));
			dispatch(setCreateEventMode(false));
		} catch (e) {
			// alert('Event could not be updated');
			toast.error('Event could not be updated');
			console.error(e);
		} finally {
			setButtonDisabled(false);
		}
    };

	const handleSeriesSave = async () => {
		if (!selectedEventRedux || !selectedEvent) return;
		setButtonDisabled(true);
		try {
			const events = await createEventSeriesFromAPI(selectedEvent, seriesOptions);
			const event = events[0];
			toast.success('Event series created');

			if (event && event.id && selectedEvent.templateId) {
				createBillFromExternalAPI(storeManagerURL, event.id, selectedEvent.templateId)
					.then((response) => {
						console.log(response);
						if (response.status === 0 && typeof response.data === "object" && response.data.gotoaddress !== '') {
							const popup = window.open(response.data.gotoaddress, 'Factura','height=1000,width=1200');
							popup?.focus();
						} else {
							console.log('No redirect required');
						}
					})
					.catch((e) => console.log(e))
					.finally(() => {
						dispatch(addEvent(selectedEventRedux));
						dispatch(setSelectedEventRedux(null));
						dispatch(setSelectedTemplateId(null));
						dispatch(setCreateEventMode(false));
						dispatch(setSelectedEventIdForPlanning(null));
						setSeriesModalOpened(false);
						dispatch(setRefreshEvents());
					});
			}

			// dispatch(setSelectedEventRedux(null));
			// dispatch(setSelectedEventIdForPlanning(null));
			// setSeriesModalOpened(false);
			// dispatch(setCreateEventMode(false));
		} catch (e) {
			toast.error('Event series could not be created');
			console.error(e);
		} finally {
			setButtonDisabled(false);
		}
	}

	const handleOpenOrder = () => {
		if (selectedEvent?.orderLink) {
			let url;
			if(eventNotEditable) {
				url = `${storeManagerURL}CCS/fac_rechedit.php?s_FilialNr=${selectedEvent.branchId}&s_Rechnungs_Nr=${selectedEvent.orderLink}&type=temp&operation=view`;
			} else {
				url = `${storeManagerURL}CCS/fac_rechedit.php?s_FilialNr=${selectedEvent.branchId}&s_Rechnungs_Nr=${selectedEvent.orderLink}&type=temp&operation=edit`;
			}
			console.log(url);
			// window.open(url, '_blank')?.focus();
			const popup = window.open(url, 'Factura','height=1000,width=1200');
			popup?.focus();
			// dispatch(setSelectedEventRedux(null));
			// dispatch(setSelectedEventIdForPlanning(null));
			// dispatch(setCreateEventMode(false));
		}
	}

	const handleOpenPrivateEventOrderDetail = () => {
		if (selectedEvent?.orderLink) {
			const url = `${storeManagerURL}CCS/fac_rechedit.php?s_FilialNr=${selectedEvent.branchId}&s_Rechnungs_Nr=${selectedEvent.orderLink}&type=temp&operation=view`;
			console.log(url);
			const popup = window.open(url, 'Factura','height=1000,width=1200');
			popup?.focus();
		}
	}

	const handleOpenPrivateEventOrderEdit = () => {
		if (selectedEvent?.orderLink) {
			getBillLinkFromExternalAPI(storeManagerURL, selectedEvent.orderLink)
			.then((response) => {
				console.log(response);
				if (response.status === 0 && response.data !== '') {
					const url = response.data;
					console.log(url);
					const popup = window.open(url, 'Factura','height=1000,width=1200');
					popup?.focus();
				} else {
					const url = `${storeManagerURL}CCS/fac_rechedit.php?s_FilialNr=${selectedEvent.branchId}&s_Rechnungs_Nr=${selectedEvent.orderLink}&type=temp&operation=edit`;
					console.log(url);
					const popup = window.open(url, 'Factura','height=1000,width=1200');
					popup?.focus();
				}
			});
		}
	}
	
	useEffect(() => {
		// Update dates in event series modal
		setSeriesOptions({...seriesOptions, fromDate: selectedEvent?.startDate || new Date(), toDate: selectedEvent?.endDate || new Date()});
		// Update resources dates
		// setSelectedEvent((prevState) => {
		// 	if (!prevState) return null;
		// 	return {...prevState, resources: prevState.resources.map((r) => ({...r, startDate: selectedEvent?.startDate || new Date(), endDate: selectedEvent?.endDate || new Date()}))};
		// });
	}, [seriesModalOpened, selectedEvent?.startDate, selectedEvent?.endDate]);

	// const eventNotEditable = useMemo(() => {
	// 	console.log('Event not editable');
	// 	return Number(selectedEvent?.status) < 100;
	// }, [selectedEvent, smEventResponse]);	

	const privateEventOrderEditButtonDisabled = useMemo(() => {
		if (!user.facturabill_modify) {
			console.log('privateEventOrderEditButtonDisabled: !user.facturabill_modify', user);
			return true;
		}

		if (smEventResponse?.Type === 'temp' && ['100', '101', '102'].includes(smEventResponse?.OldStatus)) {
			console.log('privateEventOrderEditButtonDisabled: temp & OldStatus 100, 101, 102', smEventResponse);
			return true;
		}

		if (smEventResponse?.Type !== 'temp' && ['3', '4'].includes(smEventResponse?.OldStatus || '')) {
			console.log('privateEventOrderEditButtonDisabled: !temp & OldStatus 3, 4', smEventResponse);
			return true;
		}

		console.log('privateEventOrderEditButtonDisabled: OK', smEventResponse);
		return false;
	}, [smEventResponse, user]);

	const eventNotEditable = useMemo(() => {
		return privateEventOrderEditButtonDisabled || apiEventNotEditable;
		// if (selectedEvent && !selectedEvent.contingented) {
		// 	return privateEventOrderEditButtonDisabled;
		// }

		// return apiEventNotEditable;
	}, [apiEventNotEditable, selectedEvent, privateEventOrderEditButtonDisabled]);

    return selectedEvent ? (
			<>
        <Modal
					open={true}
					onClose={handleCancel}
					leftTitle={`${selectedEvent.originalTemplateName || selectedEvent.sourceTemplateName}`}
					title={`${selectedEvent.name || ""}`}
					editableTitle={true}
					editableLeftTitle={true}
					onTitleChange={(v) => {
						setSelectedEvent((prevState) => {
							if (!prevState) return null;
							console.log(v);
							return {...prevState, name: v};
						});
					}}
					onLeftTitleChange={(v) => {
						setSelectedEvent((prevState) => {
							if (!prevState) return null;
							console.log(v);
							return {...prevState, sourceTemplateName: v};
						});
					}}
            showCloseBtn={true}
            maxWidth="xl"
        >
            <div className="event-detail-edit-table">
                <Row>
                    <Col>
						{editMsg && (
							<>
								<Row>
									<Col>
										<Alert severity="warning">
											{editMsg}
										</Alert>
									</Col>
								</Row>
								<Divider className="divider" />
							</>
						)}
                        <Row gap="5px">
                            <Col>
                                <DateTimePickerField
									disabled={eventNotEditable}
                                    label={t("from")}
                                    labelType="out"
                                    value={selectedEvent.startDate}
                                    onChange={(v) => {
										setSelectedEvent((prevState) => {
											if (!prevState) return null;
											// if (eventNotEditable) {
											// 	return prevState;
											// }
											return {...prevState, startDate: v || new Date()};
										});
									}}
                                />
                            </Col>
                            <Col>
                                <DateTimePickerField
									disabled={eventNotEditable}
                                    label={t("to")}
                                    labelType="out"
                                    value={selectedEvent.endDate}
                                    onChange={(v) => {
										setSelectedEvent((prevState) => {
											if (!prevState) return null;
											return {...prevState, endDate: v || new Date()};
										});
									}}
                                />
                            </Col>
							<Col justify={'center'}>
								<Row>
									<CheckboxField
										label={t("contingent")}
										value={selectedEvent.contingented}
										disabled={!!selectedEvent.orderLink || eventNotEditable}
										onChange={(v) => {
											setSelectedEvent((prevState) => {
												if (!prevState) return null;
												return {...prevState, contingented: v};
											});
										}}
									/>
									{/* <CheckboxField
										label={t("exclusive")}
										value={selectedEvent.exclusive}
										onChange={(v) => {
											setSelectedEvent((prevState) => {
												if (!prevState) return null;
												return {...prevState, exclusive: v};
											});
										}}
									/> */}
								</Row>
							</Col>
                        </Row>
                        <Row gap="5px">
                            <Col>
                                <NumberField
                                    label={t("minimum_visitors")}
                                    labelType="out"
                                    value={selectedEvent.minVisitors}
									disabled={eventNotEditable}
                                    onChange={(v) => {
										setSelectedEvent((prevState) => {
											if (!prevState) return null;
											return {...prevState, minVisitors: v || 0};
										});
									}}
                                />
                            </Col>
                            <Col>
                                <NumberField
                                    label={t("maximum_visitors")}
                                    labelType="out"
                                    value={selectedEvent.maxVisitors}
									disabled={eventNotEditable}
                                    onChange={(v) => {
										setSelectedEvent((prevState) => {
											if (!prevState) return null;
											return {...prevState, maxVisitors: v || 0};
										});
									}}
                                />
                            </Col>
							<Col>
								<NumberField
									label={t("current_visitors")}
									labelType="out"
									value={selectedEvent.currentVisitors}
									disabled={eventNotEditable}
									readOnly={true}
									onChange={(v) => {
										setSelectedEvent((prevState) => {
											if (!prevState) return null;
											return {...prevState, currentVisitors: v || 0};
										});
									}}
								/>
							</Col>
							<Col>
								<NumberField
									label={t("offline_visitors")}
									labelType="out"
									value={selectedEvent.offlineVisitors}
									disabled={eventNotEditable}
									readOnly={true}
									onChange={(v) => {
										setSelectedEvent((prevState) => {
											if (!prevState) return null;
											return {...prevState, offlineVisitors: v || 0};
										});
									}}
								/>
							</Col>
							<Col>
								<NumberField
									label={t("offset_offline")}
									labelType="out"
									value={selectedEvent.offsetOffline}
									disabled={eventNotEditable}
									onChange={(v) => {
										setSelectedEvent((prevState) => {
											if (!prevState) return null;
											return {...prevState, offsetOffline: v || 0};
										});
									}}
								/>
							</Col>
                        </Row>
                        <Divider className="divider" />
                        <ResourcesEditTable
							event={selectedEvent}
                            resources={selectedEvent.resources}
							resourceGroups={resourceGroups}
							eventNotEditable={eventNotEditable}
                            onChange={(resources) => {
                            	if (selectedEvent.resources !== resources) {
									// dispatch(setEventResources(selectedEvent.id, resources));
									setSelectedEvent((prevState) => {
										if (!prevState) return null;
										return {...prevState, resources};
									});
								}
							}}
                        />
                        {/*<Divider className="divider" />*/}
                        {/*<PositionsEditTable />*/}
                        <Divider className="divider" />
                        <Row>
                            <Col>
                                <TextField
                                    label={t("additional_text")}
                                    labelType="out"
                                    value={selectedEvent.description}
									disabled={eventNotEditable}
                                    onChange={(v) => {
										setSelectedEvent((prevState) => {
											if (!prevState) return null;
											return {...selectedEvent, description: v || ''};
										});
									}}
                                    rows={3}
                                />
                            </Col>
                        </Row>
						{isVirtual ? (
							<Row justify="space-between">
								<ButtonField onClick={handleCancel}>
									{t("cancel")}
								</ButtonField>
								<ButtonField onClick={handleSaveDraft}>
									{t("save_draft")}
								</ButtonField>
								{selectedEvent.contingented && (
									<ButtonField color="primary" onClick={() => setSeriesModalOpened(true)}>
										{t("create_event_series")}
									</ButtonField>
								)}
								<ButtonField color="primary" onClick={handleCreate} disabled={buttonDisabled}>
									{t("save_to_db")}
								</ButtonField>
							</Row>
						) : (
							<Row justify="space-between">
								<ButtonField onClick={handleCancel}>
									{t("cancel")}
								</ButtonField>
								{(selectedEvent.contingented && selectedEvent.orderLinkType === '2') && (
									<ButtonField onClick={handleOpenOrder}>
										{t("open_order")}
									</ButtonField>
								)}
								{!selectedEvent.contingented && (
									<Col>
										<Row justify="center" gap="2">
											<ButtonField onClick={handleOpenPrivateEventOrderDetail}>
												{t("open_order")}
											</ButtonField>
											<ButtonField
												disabled={privateEventOrderEditButtonDisabled}
												onClick={handleOpenPrivateEventOrderEdit}
												className="ml-1"
											>
												{t("edit_order")}
											</ButtonField>
										</Row>
									</Col>
								)}
								{/* {(eventMovable(selectedEvent) || !selectedEvent.contingented) && ( */}
									<ButtonField color="primary" onClick={handleSave} disabled={buttonDisabled}>
										{t("save")}
									</ButtonField>
								{/* )} */}
							</Row>
						)}
                    </Col>
                </Row>
            </div>
        </Modal>
				{seriesModalOpened && (
					<Modal
							open={true}
							eventModal={false}
							onClose={() => setSeriesModalOpened(false)}
							title={t("create_event_series")}
							showCloseBtn={true}
							maxWidth="lg"
						>
							<Row gap="5px">
								<Col>
									<DatePickerField
										label={t("from")}
										labelType="out"
										value={seriesOptions.fromDate}
										onChange={(v) => setSeriesOptions((prevState) => ({...prevState, fromDate: v || new Date()}))}
									/>
								</Col>
								<Col>
									<DatePickerField
										label={t("to")}
										labelType="out"
										value={seriesOptions.toDate}
										onChange={(v) => setSeriesOptions((prevState) => ({...prevState, toDate: v || new Date()}))}
									/>
								</Col>
							</Row>
							<Row gap="5px">
								{/* <Col justify={'center'}>
									<CheckboxField
										label={t("whole_day")}
										value={seriesOptions.wholeDay}
										onChange={(v) => setSeriesOptions((prevState) => ({...prevState, wholeDay: v}))}
									/>
								</Col> */}
							</Row>
							<Row gap="5px">
								<Col>
									<NumberField
										label={t("each")}
										labelType="in"
										value={seriesOptions.interval}
										onChange={(v) => setSeriesOptions((prevState) => ({...prevState, interval: v || 1}))}
									/>
								</Col>
								<Col>
									<SelectField<SeriesOptionsType["seriesType"]>
										label={t("interval")}
										value={seriesOptions.seriesType}
										options={[{value: 'week', label: t('week')}, {value: 'month', label: t('month')}]}
										onChange={(v) => setSeriesOptions((prevState) => ({...prevState, seriesType: v || 'week'}))}
									/>
								</Col>
							</Row>
							<Row>
								<CheckboxField
									label={t("monday")}
									value={seriesOptions.days.includes(1)}
									onChange={(v) => setSeriesOptions((prevState) => ({...prevState, days: v ? [...prevState.days, 1] : prevState.days.filter((d) => d !== 1)}))}
								/>
								<CheckboxField
									label={t("tuesday")}
									value={seriesOptions.days.includes(2)}
									onChange={(v) => setSeriesOptions((prevState) => ({...prevState, days: v ? [...prevState.days, 2] : prevState.days.filter((d) => d !== 2)}))}
								/>
								<CheckboxField
									label={t("wednesday")}
									value={seriesOptions.days.includes(3)}
									onChange={(v) => setSeriesOptions((prevState) => ({...prevState, days: v ? [...prevState.days, 3] : prevState.days.filter((d) => d !== 3)}))}
								/>
								<CheckboxField
									label={t("thursday")}
									value={seriesOptions.days.includes(4)}
									onChange={(v) => setSeriesOptions((prevState) => ({...prevState, days: v ? [...prevState.days, 4] : prevState.days.filter((d) => d !== 4)}))}
								/>
								<CheckboxField
									label={t("friday")}
									value={seriesOptions.days.includes(5)}
									onChange={(v) => setSeriesOptions((prevState) => ({...prevState, days: v ? [...prevState.days, 5] : prevState.days.filter((d) => d !== 5)}))}
								/>
								<CheckboxField
									label={t("saturday")}
									value={seriesOptions.days.includes(6)}
									onChange={(v) => setSeriesOptions((prevState) => ({...prevState, days: v ? [...prevState.days, 6] : prevState.days.filter((d) => d !== 6)}))}
								/>
								<CheckboxField
									label={t("sunday")}
									value={seriesOptions.days.includes(7)}
									onChange={(v) => setSeriesOptions((prevState) => ({...prevState, days: v ? [...prevState.days, 7] : prevState.days.filter((d) => d !== 7)}))}
								/>
							</Row>
							<Row>
								<Col></Col>
								<Col></Col>
								<Col>
									<ButtonField color="primary" onClick={handleSeriesSave} disabled={buttonDisabled}>
										{t("save")}
									</ButtonField>
								</Col>
							</Row>
						</Modal>
				)}
			</>
    ) : (
        <></>
    );
};

export default EventEditTable;
