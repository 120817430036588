import React, { FC } from "react";
import Calendar from "react-calendar";
// import styled from "styled-components";

interface Props {
    value: Date;
    onChange: (value: Date | Date[]) => void;
}

const MiniCalendar: FC<Props> = ({ value, onChange, ...props }) => {
    return <Calendar value={value} onChange={onChange} locale="de" />;
};

// const StyledCalendar = styled(Calendar)`
//     height: 100%;
//     & .react-calendar__navigation {
//         background-color: red;
//         height: 50px;
//     }
//     & .react-calendar__viewContainer {
//         background-color: blue;
//         height: 200px;
//     }
//     & .react-calendar__month-view {
//         height: 200px;
//     }
// `;

export default MiniCalendar;
