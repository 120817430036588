import React, { FC, useContext } from "react";

import Col from "../Layout/Col";
import Row from "../Layout/Row";
import TimeSlot from "./TimeSlot";
import { HandlersContext } from "./Calendar";
import { objectsEquals } from "../../helpers/helpers";
import {useSelector} from "react-redux";
import {getCalendarDisplayType} from "../../redux/selectors/filterSelectors";
import {getBranchItem, getBranchItemsConflicts} from "../../redux/selectors/branchItemsSelectors";
import cn from "classnames";

export interface SupplierType {
	id: string;
	name: string;
    available: boolean;
    availabilityColor: string | null;
    spentHours: number | null;
    availableHours: number | null;
    maxHours: number | null;
}

export interface SupplierAvailabilityType {
	[key: string]: {
		invitedAt: string,
		inviteFlag: boolean,
		acceptedAt: string,
		onHoldAt: string,
		deniedAt: string,
		denyFlag: boolean,
        confirmedAt: string,
        confirmFlag: boolean,
	}
}

export interface ResourceType {
    id: string;
    name: string | null;
    addresses: SupplierType[];
    startDate: Date | null;
    endDate: Date | null;
    minStock: number | null;
    currentStock: number | null;
    fullStock: number | null;
	actualPrice: number | null;
    label: string | null;
    itemName: string | null;
    color?: string | null;
    prepTime?: string | null;
    followupTime?: string | null;
    status?: boolean | null;
    eventId?: string | null;
    itemId?: string | null;
    oldItemId?: string | null;
    branchId?: string | null;
	addressnr?: string | null;
	eventItemNr?: string | null;
	itemTypeNr: string;
	supplierlist: SupplierAvailabilityType | null;
	controlSequence: string;
    positionId: string;
    eventtype?: string | null;
    conditions?: object | null;
    unitNr: string;
}

interface ResourceTypeRowProps {
    resourceGroup: ResourceType[];
}

const CalendarResourceRow: FC<ResourceTypeRowProps> = ({ resourceGroup }) => {
    // console.log(">>> CALENDAR RESOURCE ROW", resourceGroup[0].id);
    const handlersContext = useContext(HandlersContext);
	const calendarDisplayType = useSelector(getCalendarDisplayType);
	const conflicts = useSelector(getBranchItemsConflicts);
	const branchItem = useSelector((state) => getBranchItem(state, resourceGroup[0].itemId || ''));

	const timeSlotValue = (resource: ResourceType) => {
		const conflicted = conflicts.includes(resource.id);
		return (
			<>
				{calendarDisplayType === 'week' &&
					<span className={cn(
						'resource-time-slot-text',
						conflicted ? 'conflict' : '',
					)}>
						{resource.currentStock}
					</span>
				}
				{calendarDisplayType === 'day' &&
					<span className={cn(
						'resource-time-slot-text',
						conflicted ? 'conflict' : '',
					)}>
						{resource.minStock} / {resource.fullStock} / {resource.currentStock}
					</span>
				}
			</>
		);
	}
    return (
        <Row>
            <Col className="event-name">
                <Row align="center" gap="2px">
                    <Col width={1}></Col>
                    <Col width={6} className="calendar-text">
                        {resourceGroup[0].name} ({branchItem?.fullStoreQty})
                    </Col>
                </Row>
                {/* {`${name}: ${startDate && format(startDate, "H:mm")} - ${
                    endDate && format(endDate, "H:mm")
                }`} */}
            </Col>
            <div className="event-space">
                {resourceGroup.map((resource, index) => (
                    <React.Fragment key={index}>
                        {resource.startDate && resource.endDate && (
                            <TimeSlot
                                id={resource.id}
								className="flex"
                                startDate={resource.startDate}
                                endDate={resource.endDate}
                                color={resource.color || undefined}
                                onDrag={handlersContext.handleDrag}
                                // onDragStart={handlersContext.handleDragStart}
                                onDragStop={handlersContext.handleDragStop}
                                onLeftResizerMouseDown={
                                    handlersContext.handleLeftResizerMouseDown
                                }
                                onRightResizerMouseDown={
                                    handlersContext.handleRightResizerMouseDown
                                }
                            >
								{timeSlotValue(resource)}
                                {/* <div style={{ userSelect: "none", overflow: "auto" }}> */}
                                {/* <SelectField<string>
                                    value={null}
                                    onChange={() => {}}
                                    emptyOption={false}
                                    options={[
                                        {
                                            value: null,
                                            label: `${minCapacity}/${freeCapacity}/${maxCapacity}`,
                                        },
                                        { value: "5", label: "5" },
                                        { value: "10", label: "10" },
                                        { value: "15", label: "15" },
                                        { value: "20", label: "20" },
                                    ]}
                                /> */}
                                {/* </div> */}
                            </TimeSlot>
                        )}
                    </React.Fragment>
                ))}
            </div>
        </Row>
    );
};

export default React.memo(CalendarResourceRow, compareFn);

function compareFn(
    prevProps: ResourceTypeRowProps,
    nextProps: ResourceTypeRowProps
) {
    if (prevProps.resourceGroup.length !== nextProps.resourceGroup.length) {
        return false;
    }
    for (let i = 0; i < nextProps.resourceGroup.length; i++) {
        if (
            !objectsEquals(
                prevProps.resourceGroup[i],
                nextProps.resourceGroup[i]
            )
        ) {
            return false;
        }
    }
    return true;
}
