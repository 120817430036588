import { useTranslation } from "react-i18next";
import { CalendarDisplayType } from "../components/Calendar/Calendar";
import {
    addDays,
    areIntervalsOverlapping,
    max,
    min,
    differenceInMinutes,
} from "date-fns";
import { ca } from "date-fns/locale";

function getFirstDayInWeek(date: Date) {
    const startDate = new Date(date);
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    const d = new Date(startDate.setDate(diff));
    return new Date(d.setHours(0, 0, 0, 0));
}

function useGetDayName(date: Date) {
    const { t } = useTranslation();
    const dayNames = [
        t("sunday"),
        t("monday"),
        t("tuesday"),
        t("wednesday"),
        t("thursday"),
        t("friday"),
        t("saturday"),
    ];
    return dayNames[date.getDay()];
}

function getStartEndDates(
    pickedDate: Date,
    calendarDisplayType: CalendarDisplayType
) {
    const startDate =
        calendarDisplayType === "day"
            ? pickedDate
            : calendarDisplayType === "week"
            ? getFirstDayInWeek(pickedDate)
            : new Date(new Date(pickedDate).setDate(1));
    const endDate =
        calendarDisplayType === "day"
            ? addDays(startDate, 1)
            : calendarDisplayType === "week"
            ? addDays(startDate, 7)
            : new Date(pickedDate.getFullYear(), pickedDate.getMonth() + 1, 1);
    return [startDate, endDate];
}

function getOverlappingMinutesInterval(
    r1: { start: Date; end: Date },
    r2: { start: Date; end: Date }
) {
    try {
        if (areIntervalsOverlapping(r1, r2)) {
            const start = max([r1.start, r2.start]);
            const end = min([r1.end, r2.end]);
            return differenceInMinutes(end, start);
        } else return 0;
    } catch (error) {
        return 0;
    }
}

function composeDateFromBaseDate(
    baseDate: Date,
    day: number,
    hours: number,
    minutes: number
) {
    const d = new Date(baseDate);
    d.setDate(day);
    d.setHours(hours, minutes);
    return d;
}

export {
    getFirstDayInWeek,
    useGetDayName,
    getStartEndDates,
    getOverlappingMinutesInterval,
    composeDateFromBaseDate,
};
