import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Icon from "../Icon";
import TimeSlot from "./TimeSlot";
import { Checkbox } from "@material-ui/core";
import { ImBin } from "react-icons/im";
import Row from "../Layout/Row";
import Col from "../Layout/Col";
import { HandlersContext } from "./Calendar";
import CalendarResourceRow, { ResourceType } from "./CalendarResourceRow";
import {
	composeEventRowId, getEventColor,
	getEventStatusName,
	groupEventsByTemplateId,
	groupResourcesByItemId,
} from "../../helpers/calendarHelpers";
import {getBranchFilter, getCalendarDisplayType} from "../../redux/selectors/filterSelectors";
import {
    getCalendarSettings,
    getCheckedEventIds,
    getOpenedDetails,
    getSalesMode,
} from "../../redux/selectors/calendarSelectors";
import {
    addOpenedDetail,
    setOpenedDetails,
    setSelectedEvent,
    setRefreshEvents,
    setCheckedEventIds,
    addCheckedEventIds,
} from "../../redux/actions/calendarActions";
import { objectsEquals } from "../../helpers/helpers";
import {BillType} from "./CalendarPositionsRow";
import {getBranchItemsConflicts} from "../../redux/selectors/branchItemsSelectors";
import cn from "classnames";
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from "react-i18next";
import { deleteEventFromAPI, deleteEventFromExternalAPI } from "../../helpers/api";
import Modal from "../Layout/Modal";
import CheckboxField from "../Fields/CheckboxField";
import { getStoreManagerURL } from "../../redux/selectors/appConfigSelectors";

export interface CalendarEventType {
    id: string;
    originalId: string;
    templateId: string | null;
    sourceTemplateId: string | null;
    name: string;
    sourceTemplateName: string | null;
    description: string;
    startDate: Date;
    endDate: Date;
    timestamp: Date;
    branchId: string | null;
    branchName: string;
    resources: ResourceType[];
    bills: BillType;
    color: string | undefined;
    parentEventId: string;
	parentSeries: string;
    user: string;
    bgColor: string;
    frameColor: string;
    frameType: string;
    status: string;
    lastChange: string;
    calendarId: string;
    orderLink: string;
    orderLinkType: string;
    exclusive: boolean;
    isBlocked: boolean;
    updated: boolean;
    minVisitors: number;
    maxVisitors: number;
    currentVisitors: number;
    offlineVisitors: number;
    offsetOffline: number;
	contingented: boolean;
	locked: boolean;
    originalTemplateName?: string;
    eventtype?: string | null;
    asNewTemplate?: boolean;
}

interface CalendarEventRowProps {
    events: CalendarEventType[];
    childEvents: CalendarEventType[];
    showCheckbox?: boolean;
    showDetailIcon?: boolean;
}

const CalendarEventRow: FC<CalendarEventRowProps> = React.memo(
    ({
        events: templateEvents,
        childEvents,
        showCheckbox = true,
        showDetailIcon = true,
    }) => {
        const [id, setId] = useState("");
        const [deleteModalOpen, setDeleteModalOpen] = useState(false);
        const [deleteSeries, setDeleteSeries] = useState(false);
        // console.log(">>> EVENT ROW", id);
        const dispatch = useDispatch();
        const branch = useSelector(getBranchFilter);
        const checkedEventIds = useSelector(getCheckedEventIds);
        const openedDetails = useSelector(getOpenedDetails);
		const conflicts = useSelector(getBranchItemsConflicts);
		const calendarDisplayType = useSelector(getCalendarDisplayType);
        const calendarSettings = useSelector(getCalendarSettings);
        const salesMode = useSelector(getSalesMode);
        const storeManagerURL = useSelector(getStoreManagerURL);
        const handlersContext = useContext(HandlersContext);
        const eventIds = templateEvents.map((te) => te.id);
        const groupedChildEvents = groupEventsByTemplateId(childEvents, ["0"]);
        const resources = templateEvents.reduce((acc, tEvent) => {
            return [...acc, ...tEvent.resources];
        }, [] as ResourceType[]);
        const groupedResources = groupResourcesByItemId(resources);
        const { t } = useTranslation();

        useEffect(() => {
            setId(composeEventRowId(templateEvents));
        }, [templateEvents]);

        const showDetail = useCallback(
            (id: string) => {
                if (!openedDetails.includes(id)) {
                    dispatch(addOpenedDetail(id));
                }
            },
            [openedDetails, dispatch]
        );

        const hideDetail = (id: string) => {
            const details = openedDetails.filter((d) => d !== id);
            dispatch(setOpenedDetails(details));
        };

        useEffect(() => {
            if (!showDetailIcon) {
                showDetail(id);
            }
        }, [showDetail, id, showDetailIcon]);

        const handleDoubleClick = useCallback(
            (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                const id =
                    e.currentTarget.parentElement &&
                    e.currentTarget.parentElement.id;
                if (id) {
                    const event = templateEvents.find((e) => e.id === id);
                    event && dispatch(setSelectedEvent(event));
                }
            },
            [dispatch, templateEvents]
        );

        const handleEventDelete = async (event: CalendarEventType) => {
            try {
                dispatch(setRefreshEvents());
                await deleteEventFromAPI(event.id, deleteSeries);
                await deleteEventFromExternalAPI(storeManagerURL, event.id, deleteSeries);
                dispatch(setRefreshEvents());
            } catch (e) {
                console.error("Error deleting event", e);
            }
        }

        const timeSlotValue = (event: CalendarEventType) => {
			const conflicted = !!conflicts.find(c => c.split('-')[0] === `e${event.id}`);
            const tooltipText = `${t("status")}: ${t(getEventStatusName(event))}\n ${event.description}`;
        	return (
				<>
					{calendarDisplayType === 'week' &&
						<Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{tooltipText}</span>}>
                            <div className={cn(
                                "event-time-slot-text",
                                conflicted ? 'conflict' : '',
                            )}></div>
                        </Tooltip>
					}
					{calendarDisplayType === 'day' &&
                        <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{tooltipText}</span>}>
                            <span className={cn(
                                "event-time-slot-text",
                                conflicted ? 'conflict' : '',
                            )}>
                                <span className="text-val">{event.currentVisitors} / {event.maxVisitors}</span>
                            </span>
                        </Tooltip>
					}
				</>
			);
		}

        return (
            <>
                <div className="calendar-event" style={{backgroundColor: `#${calendarSettings[templateEvents[0].calendarId]?.BackgroundColor}`}}>
                    <Row>
                        <Col className="event-name">
                            <Row align="center" gap="2px">
                                {showDetailIcon && (
                                    <Col width={1}>
                                        {!openedDetails.includes(id) ? (
                                            <Icon
                                                name="add"
                                                size="22px"
                                                onClick={(e) => {
                                                    showDetail(id);
                                                }}
                                            />
                                        ) : (
                                            <Icon
                                                name="remove"
                                                size="22px"
                                                onClick={(e) => {
                                                    hideDetail(id);
                                                }}
                                            />
                                        )}
                                    </Col>
                                )}
                                <Col width={5} className="calendar-text">
                                    {`${templateEvents[0].name} ${
                                        branch === null
                                            ? "(" +
                                            templateEvents[0].branchName +
                                            ")"
                                            : ""
                                    }`}
                                </Col>
                                {showCheckbox && (
                                    <Col width={1}>
                                        <ImBin
                                            name="remove"
                                            size="16px"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setDeleteModalOpen(true)}
                                        />
                                        {/* <Checkbox
                                            checked={checkedEventIds.includes(
                                                eventIds[0]
                                            )}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    dispatch(
                                                        addCheckedEventIds(eventIds)
                                                    );
                                                } else {
                                                    const newCheckedIds = checkedEventIds.filter(
                                                        (eventId) =>
                                                            !eventIds.includes(
                                                                eventId
                                                            )
                                                    );
                                                    dispatch(
                                                        setCheckedEventIds(
                                                            newCheckedIds
                                                        )
                                                    );
                                                }
                                            }}
                                        /> */}
                                    </Col>
                                )}
                            </Row>
                        </Col>
                        <div className="event-space">
                            {templateEvents.map((tEvent, index) => (
                                <TimeSlot
                                    key={index}
                                    id={tEvent.id}
                                    className="event flex"
                                    color={getEventColor(tEvent, calendarSettings)}
                                    startDate={tEvent.startDate}
                                    endDate={tEvent.endDate}
                                    draggable={showCheckbox || tEvent.id === 'virtual'}
                                    onDrag={handlersContext.handleDrag}
                                    onDragStop={handlersContext.handleDragStop}
                                    onLeftResizerMouseDown={
                                        handlersContext.handleLeftResizerMouseDown
                                    }
                                    onRightResizerMouseDown={
                                        handlersContext.handleRightResizerMouseDown
                                    }
                                    onDoubleClick={handleDoubleClick}
                                >
                                    {timeSlotValue(tEvent)}
                                </TimeSlot>
                            ))}
                        </div>
                    </Row>
                    {openedDetails.includes(id) &&
                        groupedResources
                            .sort((r1, r2) => {
                                return r1[0].itemId &&
                                    r2[0].itemId &&
                                    r1[0].itemId > r2[0].itemId
                                    ? 1
                                    : -1;
                            })
                            .filter((resGroup) => {
                                if (salesMode) {
                                    return resGroup[0].status && resGroup[0].controlSequence === 'T';
                                }

                                return resGroup[0].status && resGroup[0].controlSequence !== 'T';
                            })
                            .map((resGroup, index) => (
                                <CalendarResourceRow
                                    key={index}
                                    resourceGroup={resGroup}
                                />
                            ))}
                    {openedDetails.includes(id) &&
                        groupedChildEvents
                            .sort((e1, e2) => {
                                return e1[0].templateId &&
                                    e2[0].templateId &&
                                    ((e1[0].templateId !== "0" &&
                                        e1[0].templateId > e2[0].templateId) ||
                                        (e1[0].templateId === "0" &&
                                            e1[0].id > e2[0].id))
                                    ? 1
                                    : -1;
                            })
                            .map((childEventGroup, index) => (
                                <CalendarEventRow
                                    key={index}
                                    events={childEventGroup}
                                    childEvents={[]} // TODO: set children when more than 1 level event will be allowed
                                />
                            ))}
                </div>
                <Modal
                    className="confirmation-modal"
                    open={deleteModalOpen}
                    eventModal={false}
                    onClose={() => setDeleteModalOpen(false)}
                    onCancel={() => setDeleteModalOpen(false)}
                    cancelBtnText={t("cancel")}
                    onSubmit={() => {
                        setDeleteModalOpen(false);
                        handleEventDelete(templateEvents[0]);
                    }}
                    submitBtnText={t("delete")}
                    title={t("confirm_deleting_events")}
                >
                    <Row>
                        {t("do_you_want_to_delete_events")}
                    </Row>
                    {templateEvents[0].parentSeries.length && (
                        <Row>
                            <CheckboxField
                                label={t("delete_series")}
                                value={deleteSeries}
                                onChange={(v) => setDeleteSeries(v)}
                            />
                        </Row>
                    )}
                </Modal>
            </>
        );
    },
    compareFn
);

export default CalendarEventRow;

function compareFn(
    prevProps: CalendarEventRowProps,
    nextProps: CalendarEventRowProps
) {
    if (
        prevProps.showCheckbox !== nextProps.showCheckbox ||
        prevProps.showDetailIcon !== nextProps.showDetailIcon ||
        prevProps.events.length !== nextProps.events.length ||
        prevProps.childEvents.length !== nextProps.childEvents.length
    ) {
        return false;
    }
    for (let i = 0; i < nextProps.events.length; i++) {
        if (!objectsEquals(prevProps.events[i], nextProps.events[i])) {
            return false;
        }
    }
    for (let i = 0; i < nextProps.childEvents.length; i++) {
        if (
            !objectsEquals(prevProps.childEvents[i], nextProps.childEvents[i])
        ) {
            return false;
        }
    }
    return true;
}
