import { WorkTime } from "../../components/Calendar/Calendar";
import { CalendarEventType } from "../../components/Calendar/CalendarEventRow";
import { TemplateWithGroupType } from "../../helpers/dataProcessor";
import { CalendarActionTypes } from "../actionTypes";
import { CalendarSettingsType, EventSortOptionType } from "../reducers/calendarReducer";

const setCalendarStartDate = (value: Date) => ({
    type: CalendarActionTypes.SET_CALENDAR_START_DATE,
    payload: {
        value,
    },
});

const setCalendarEndDate = (value: Date) => ({
    type: CalendarActionTypes.SET_CALENDAR_END_DATE,
    payload: {
        value,
    },
});

const setWorkTimeStart = (value: WorkTime) => ({
    type: CalendarActionTypes.SET_WORKTIME_START,
    payload: {
        value,
    },
});

const setWorkTimeEnd = (value: WorkTime) => ({
    type: CalendarActionTypes.SET_WORKTIME_END,
    payload: {
        value,
    },
});

const setCheckedEventIds = (value: string[]) => ({
    type: CalendarActionTypes.SET_CHECKED_EVENT_IDS,
    payload: {
        value,
    },
});

const addCheckedEventIds = (value: string[]) => ({
    type: CalendarActionTypes.ADD_CHECKED_EVENT_IDS,
    payload: {
        value,
    },
});

const setOpenedDetails = (value: string[]) => ({
    type: CalendarActionTypes.SET_OPENED_DETAILS,
    payload: {
        value,
    },
});

const addOpenedDetail = (value: string) => ({
    type: CalendarActionTypes.ADD_OPENED_DETAIL,
    payload: {
        value,
    },
});

const setCreateEventMode = (value: boolean) => ({
    type: CalendarActionTypes.SET_CREATE_EVENT_MODE,
    payload: {
        value,
    },
});

const setSalesMode = (value: boolean) => ({
    type: CalendarActionTypes.SET_SALES_MODE,
    payload: {
        value,
    },
});

const setSelectedEvent = (value: CalendarEventType | null) => ({
    type: CalendarActionTypes.SET_SELECTED_EVENT,
    payload: {
        value,
    },
});

const setSelectedTemplateId = (value: string | null) => ({
    type: CalendarActionTypes.SET_SELECTED_TEMPLATE_ID,
    payload: {
        value,
    },
});

const setPickedTemplate = (value: TemplateWithGroupType | null) => ({
    type: CalendarActionTypes.SET_PICKED_TEMPLATE,
    payload: {
        value,
    },
});

const setSelectedEventIdForPlanning = (value: string | null) => ({
	type: CalendarActionTypes.SET_SELECTED_EVENT_ID_FOR_PLANNING,
	payload: {
		value,
	},
});

const setNewEvent = (value: CalendarEventType | null) => ({
    type: CalendarActionTypes.SET_NEW_EVENT,
    payload: {
        value,
    },
});

const setLoading = (value: boolean) => ({
    type: CalendarActionTypes.SET_LOADING,
    payload: {
        value,
    },
});

const setShouldAutoRefresh = (value: boolean) => ({
	type: CalendarActionTypes.SET_SHOULD_AUTO_REFRESH,
	payload: {
		value,
	},
});

const setCalendarSettings = (value: CalendarSettingsType) => ({
	type: CalendarActionTypes.SET_CALENDAR_SETTINGS,
	payload: {
		value,
	},
});

const setEventSortFilter = (value: EventSortOptionType) => ({
    type: CalendarActionTypes.SET_CALENDAR_EVENT_SORT,
    payload: {
        value,
    },
});

const setRefreshEvents = () => ({
    type: CalendarActionTypes.SET_RELOAD_EVENTS,
});

export {
    setCalendarStartDate,
    setCalendarEndDate,
    setWorkTimeStart,
    setWorkTimeEnd,
    setCheckedEventIds,
    addCheckedEventIds,
    addOpenedDetail,
    setOpenedDetails,
    setCreateEventMode,
    setSalesMode,
    setSelectedEvent,
    setSelectedTemplateId,
    setPickedTemplate,
	setSelectedEventIdForPlanning,
    setNewEvent,
    setLoading,
	setShouldAutoRefresh,
    setCalendarSettings,
    setEventSortFilter,
    setRefreshEvents,
};
