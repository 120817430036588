import { CalendarDisplayType } from "../../components/Calendar/Calendar";
import { FilterActionTypes } from "../actionTypes";

export interface FiltersState {
    pickedDate: Date;
    calendarId: string | null;
    branchGroup: string | null;
    branch: string | null;
    templateGroup: string | null;
    template: string | null;
    resourceGroup: string | null;
    resource: string | null;
    specialResource: string | null;
    unasignedResources: string | null;
    search: string | null;
    calendarDisplayType: CalendarDisplayType;
    eventsWithoutOrder: boolean;
    showBlockers: boolean;
    suppliers: string | null;
}

const initFilters = {
    pickedDate: new Date(),
    calendarId: null,
    branchGroup: null,
    branch: null,
    templateGroup: null,
    template: null,
    resourceGroup: null,
    resource: null,
    specialResource: null,
    unasignedResources: null,
    search: null,
    calendarDisplayType: "week" as CalendarDisplayType,
    eventsWithoutOrder: false,
    showBlockers: false,
    suppliers: null,
};

function filtersReducer(
    state: FiltersState = initFilters,
    action: { type: string; payload?: any }
) {
    switch (action.type) {
        case FilterActionTypes.SET_FILTER_PICKED_DATE:
            // console.log("REDUX - filter pickedDate");
            return { ...state, pickedDate: action.payload.value };
        case FilterActionTypes.SET_FILTER_CALENDAR_ID:
            // console.log("REDUX - filter calendarId");
            return { ...state, calendarId: action.payload.value };
        case FilterActionTypes.SET_FILTER_CALENDAR_DISPLAY_TYPE:
            // console.log("REDUX - filter calendarDisplayType");
            return { ...state, calendarDisplayType: action.payload.value };
        case FilterActionTypes.SET_FILTER_EVENTS_WITHOUT_ORDER:
            // console.log("REDUX - filter eventWithoutOrder");
            return { ...state, eventsWithoutOrder: action.payload.value };
            case FilterActionTypes.SET_FILTER_SHOW_BLOCKERS:
                // console.log("REDUX - filter showBlockers");
                return { ...state, showBlockers: action.payload.value };
        case FilterActionTypes.SET_FILTER_BRANCH_GROUP:
            // console.log("REDUX - filter branchGroup");
            return { ...state, branchGroup: action.payload.value };
        case FilterActionTypes.SET_FILTER_BRANCH:
            // console.log("REDUX - filter branch");
            return { ...state, branch: action.payload.value };
        case FilterActionTypes.SET_FILTER_TEMPLATE_GROUP:
            return { ...state, templateGroup: action.payload.value };
        case FilterActionTypes.SET_FILTER_TEMPLATE:
            // console.log("REDUX - filter template");
            return { ...state, template: action.payload.value };
        case FilterActionTypes.SET_FILTER_RESOURCE_GROUP:
            // console.log("REDUX - filter resourceGroup");
            return { ...state, resourceGroup: action.payload.value };
        case FilterActionTypes.SET_FILTER_RESOURCE:
            // console.log("REDUX - filter resource");
            return { ...state, resource: action.payload.value };
		case FilterActionTypes.SET_FILTER_SPEACIAL_RESOURCE:
			// console.log("REDUX - filter resource");
			return { ...state, specialResource: action.payload.value };
        case FilterActionTypes.SET_FILTER_SEARCH:
            return { ...state, search: action.payload.value };
        case FilterActionTypes.SET_FILTER_SUPPLIERS:
            return { ...state, suppliers: action.payload.value };
        default:
            return state;
    }
}

export default filtersReducer;
