export const FilterActionTypes = {
    SET_FILTER_PICKED_DATE: "SET_FILTER_PICKED_DATE",
    SET_FILTER_CALENDAR_ID: "SET_FILTER_CALENDAR_ID",
    SET_FILTER_CALENDAR_DISPLAY_TYPE: "SET_FILTER_CALENDAR_DISPLAY_TYPE",
    SET_FILTER_EVENTS_WITHOUT_ORDER: "SET_FILTER_EVENTS_WITHOUT_ORDER",
    SET_FILTER_BRANCH_GROUP: "SET_FILTER_BRANCH_GROUP",
    SET_FILTER_BRANCH: "SET_FILTER_BRANCH",
    SET_FILTER_TEMPLATE_GROUP: "SET_FILTER_TEMPLATE_GROUP",
    SET_FILTER_TEMPLATE: "SET_FILTER_TEMPLATE",
    SET_FILTER_RESOURCE_GROUP: "SET_FILTER_RESOURCE_GROUP",
    SET_FILTER_RESOURCE: "SET_FILTER_RESOURCE",
    SET_FILTER_SPEACIAL_RESOURCE: "SET_FILTER_SPEACIAL_RESOURCE",
    // SET_FILTER_UNASIGNED_RESOURCES: "SET_FILTER_UNASIGNED_RESOURCES",
    SET_FILTER_SEARCH: "SET_FILTER_SEARCH",
    // SET_FILTER_EVENT_SORT: "SET_FILTER_EVENT_SORT",
    SET_FILTER_SHOW_BLOCKERS: "SET_FILTER_SHOW_BLOCKERS",
    SET_FILTER_SUPPLIERS: "SET_FILTER_SUPPLIERS",
};

export const FilterOptsActionTypes = {
    SET_FILTER_OPTIONS: "SET_FILTER_OPTIONS",
    SET_FILTER_OPT_BRANCH_GROUP: "SET_FILTER_OPT_BRANCH_GROUP",
    SET_FILTER_OPT_BRANCH: "SET_FILTER_OPT_BRANCH",
    // SET_FILTER_OPT_TEMPLATE_GROUP: "SET_FILTER_OPT_TEMPLATE_GROUP",
    SET_FILTER_OPT_TEMPLATE: "SET_FILTER_OPT_TEMPLATE",
    SET_FILTER_OPT_RESOURCE_GROUP: "SET_FILTER_OPT_RESOURCE_GROUP",
    SET_FILTER_OPT_RESOURCE: "SET_FILTER_OPT_RESOURCE",
    SET_FILTER_OPT_SUPPLIERS: "SET_FILTER_OPT_SUPPLIERS",
};

export const EventsActionTypes = {
    SET_EVENTS: "SET_EVENTS",
    SET_EVENT_START_END_DATE: "SET_EVENT_START_END_DATE",
    SET_EVENT_RESOURCES_START_END_DATES: "SET_EVENT_RESOURCES_START_END_DATES",
    SET_EVENTS_UPDATED: "SET_EVENTS_UPDATED",
    SET_EVENT_RESOURCES: "SET_EVENT_RESOURCES",
    SET_EVENT_RESOURCES_CURRENT_STOCK: "SET_EVENT_RESOURCES_CURRENT_STOCK",
    UPDATE_EVENT: "UPDATE_EVENT",
    ADD_EVENT: "ADD_EVENT",
};

export const BranchItemsActionTypes = {
	SET_BRANCHITEMS: "SET_BRANCHITEMS",
	SET_BRANCHITEMS_CONFLICTS: "SET_BRANCHITEMS_CONFLICTS",
	SET_AVAILABLE_RESOURCES_QTY: "SET_AVAILABLE_RESOURCES_QTY",
    SET_RESOURCE_ITEMS: "SET_RESOURCE_ITEMS",
}

export const CalendarActionTypes = {
    SET_CALENDAR_START_DATE: "SET_CALENDAR_START_DATE",
    SET_CALENDAR_END_DATE: "SET_CALENDAR_END_DATE",
    SET_WORKTIME_START: "SET_WORKTIME_START",
    SET_WORKTIME_END: "SET_WORKTIME_END",
    SET_CHECKED_EVENT_IDS: "SET_CHECKED_EVENT_IDS",
    ADD_CHECKED_EVENT_IDS: "ADD_CHECKED_EVENT_IDS",
    SET_OPENED_DETAILS: "SET_OPENED_DETAILS",
    ADD_OPENED_DETAIL: "ADD_OPENED_DETAIL",
    SET_CREATE_EVENT_MODE: "SET_CREATE_EVENT_MODE",
    SET_SALES_MODE: "SET_SALES_MODE",
    SET_SELECTED_EVENT: "SET_SELECTED_EVENT",
    SET_SELECTED_TEMPLATE_ID: "SET_SELECTED_TEMPLATE_ID",
    SET_SELECTED_EVENT_ID_FOR_PLANNING: "SET_SELECTED_EVENT_ID_FOR_PLANNING",
    SET_NEW_EVENT: "SET_NEW_EVENT",
    SET_LOADING: "SET_LOADING",
    SET_SHOULD_AUTO_REFRESH: "SET_SHOULD_AUTO_REFRESH",
    SET_CALENDAR_SETTINGS: "SET_CALENDAR_SETTINGS",
    SET_CALENDAR_EVENT_SORT: "SET_CALENDAR_EVENT_SORT",
    SET_PICKED_TEMPLATE: "SET_PICKED_TEMPLATE",
    SET_RELOAD_EVENTS: "SET_RELOAD_EVENTS",
};

export const PrintModeActionTypes = {
    SET_PRINT_MODE: "SET_PRINT_MODE",
};

export const AppConfigActionTypes = {
	SET_APP_CONFIG: "SET_APP_CONFIG",
};

export const UserActionTypes = {
	SET_USER: "SET_USER",
};
