import { WorkTime } from "../../components/Calendar/Calendar";
import { CalendarEventType } from "../../components/Calendar/CalendarEventRow";
import { TemplateWithGroupType } from "../../helpers/dataProcessor";
import { CalendarSettingsType, EventSortOptionType } from "../reducers/calendarReducer";

export const getCalendarStartDate = (store: any): Date => {
    return store.calendar.calendarStartDate;
};

export const getCalendarEndDate = (store: any): Date => {
    return store.calendar.calendarEndDate;
};

export const getWorkTimeStart = (store: any): WorkTime => {
    return store.calendar.workTimeStart;
};

export const getWorkTimeEnd = (store: any): WorkTime => {
    return store.calendar.workTimeEnd;
};

export const getCheckedEventIds = (store: any): string[] => {
    return store.calendar.checkedEventIds;
};

export const getOpenedDetails = (store: any): string[] => {
    return store.calendar.openedDetails;
};

export const getCreateEventMode = (store: any): boolean => {
    return store.calendar.createEventMode;
};

export const getSalesMode = (store: any): boolean => {
    return store.calendar.salesMode;
};

export const getSelectedEvent = (store: any): CalendarEventType | null => {
    return store.calendar.selectedEvent;
};

export const getSelectedTemplateId = (store: any): string | null => {
    return store.calendar.selectedTemplateId;
};

export const getPickedTemplate = (store: any): TemplateWithGroupType | null => {
    return store.calendar.pickedTemplate;
};

export const getSelectedEventIdForPlanning = (store: any): string | null => {
	return store.calendar.selectedEventIdForPlanning;
};

export const getNewEvent = (store: any): CalendarEventType | null => {
    return store.calendar.newEvent;
};

export const getLoading = (store: any): boolean => {
    return store.calendar.isLoading;
};

export const getShouldAutoRefresh = (store: any): boolean => {
	return store.calendar.shouldAutoRefresh;
};

export const getCalendarSettings = (store: any): CalendarSettingsType => {
	return store.calendar.calendarSettings;
};

export const getEventSortFilter = (store: any): EventSortOptionType => {
    return store.calendar.eventSortOption;
};

export const getEventRefresh = (store: any): number => {
    return store.calendar.eventRefresh;
};
