import React, { FC } from "react";
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
} from "@material-ui/core";

import ButtonField from "../Fields/ButtonField";
import Row from "./Row";
import Icon from "../Icon";
import EditableTitleField from "../Fields/EditableTitleField";
import Col from "./Col";
import { useTranslation } from "react-i18next";

interface Props {
    open: boolean;
    eventModal?: boolean;
    leftTitle?: string;
    title?: string;
	editableTitle?: boolean;
    editableLeftTitle?: boolean;
    onTitleChange?: (value: string) => void;
    onLeftTitleChange?: (value: string) => void;
    onClose: () => void;
    onSubmit?: () => void;
    submitBtnText?: string;
    onCancel?: () => void;
    cancelBtnText?: string;
    showCloseBtn?: boolean;
    className?: string;
    maxWidth?: false | "lg" | "md" | "sm" | "xs" | "xl";
    fullWidth?: boolean;
}

const Modal: FC<Props> = ({
    open,
    eventModal = true,
    leftTitle,
    title,
    editableTitle = false,
    editableLeftTitle = false,
    onTitleChange,
    onLeftTitleChange,
    onClose,
    onSubmit,
    submitBtnText,
    onCancel,
    cancelBtnText,
    showCloseBtn = false,
    className,
    maxWidth,
    fullWidth = false,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={fullWidth}
            maxWidth={maxWidth || false}
        >
            <div className={className}>
                {(title || editableTitle || leftTitle || editableLeftTitle) && (
                    <DialogTitle>
                        <Row justify="space-between">
                            <Col>
                                {(!leftTitle && !editableLeftTitle) && (<Row></Row>)}
                                {(leftTitle || editableLeftTitle) && (
                                    <Row>
                                    {t('template_name')}
                                    {!editableLeftTitle && `<span>${leftTitle}</span>`}
                                    {editableLeftTitle && <EditableTitleField value={leftTitle || ''} className="editable-title" onChange={onLeftTitleChange || (() => {})} />}
                                </Row>
                                )}
                            </Col>
                            <Col>
                                <Row justify="flex-end">
                                    {eventModal && `${t('event_name')}: `}
                                    {editableTitle && <EditableTitleField value={title || ''} className="editable-title" onChange={onTitleChange || (() => {})} />}
                                    {!editableTitle && <span style={{marginRight: '1rem'}}>{title}</span>}
                                    {showCloseBtn && (
                                        <Icon name="close" style={{alignSelf: 'center'}} onClick={onClose} />
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </DialogTitle>
                )}
                <DialogContent dividers>{props.children}</DialogContent>
                {(onSubmit || onCancel) && (
                    <DialogActions>
                        {onCancel && (
                            <ButtonField onClick={() => onCancel()}>
                                {cancelBtnText}
                            </ButtonField>
                        )}
                        {onSubmit && (
                            <ButtonField color="primary"  onClick={() => onSubmit()}>
                                {submitBtnText}
                            </ButtonField>
                        )}
                    </DialogActions>
                )}
            </div>
        </Dialog>
    );
};

export default Modal;
