import React, { FC, useEffect, createContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";

import { getStartEndDates } from "../../helpers/dateHelpers";
import {
    getBranchResourcesFromAPI,
    getCalendarSettingsFromAPI,
    getEventsFromAPI,
} from "../../helpers/api";
import EventEditTable from "../EventDetailsTable/EventEditTable";
import WeekCalendar from "./WeekCalendar";
import DayCalendar from "./DayCalendar";
import MonthCalendar from "./MonthCalendar";
import "./calendar.css";
import {
    getCalendarSettings,
	getCreateEventMode,
	getEventRefresh,
	getEventSortFilter,
	getSelectedEvent,
	getShouldAutoRefresh,
	getWorkTimeStart
} from "../../redux/selectors/calendarSelectors";
import {
    setWorkTimeStart,
    setWorkTimeEnd,
    setCalendarStartDate,
    setCalendarEndDate,
    setLoading,
    setCalendarSettings,
} from "../../redux/actions/calendarActions";
import { getCalendarId, getFilters } from "../../redux/selectors/filterSelectors";
import { setEvents } from "../../redux/actions/eventsActions";
import {setBranchItems, setBranchItemsConflicts, setResourceItems} from "../../redux/actions/branchItemsActions";
import {findOverlappingEvents, getStartEndTimeForMultipleCalendars, sortEventsByKey} from "../../helpers/calendarHelpers";
import {FiltersState} from "../../redux/reducers/filtersReducer";
import {useTranslation} from "react-i18next";
import {getFilterOptions} from "../../redux/selectors/filterOptionsSelectors";
import { setCalendarDisplayTypeFilter } from "../../redux/actions/filterActions";
import { filter } from "lodash";

export type CalendarDisplayType = "day" | "week" | "month";

export interface ResizeReffProps {
    activeTimeBoxId: string | null;
    resizeLastUpdateScreenX: number | null;
    resizeDeltaX: number;
    isResizeLeftActive: boolean;
    isResizeRightActive: boolean;
    resizeStartTime: Date | null;
    resizeEndTime: Date | null;
}

export interface WorkTime {
    hours: number;
    minutes: number;
}

export interface HandlersContextType {
    handleDrag: (e: any, ui: any) => void;
    handleDragStop: (e: any, ui: any) => void;
    handleLeftResizerMouseDown: (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => void;
    handleRightResizerMouseDown: (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => void;
}

export const initHandlersContext = {
    handleDrag: (e: any, ui: any) => {},
    handleDragStop: (e: any, ui: any) => {},
    handleLeftResizerMouseDown: (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {},
    handleRightResizerMouseDown: (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {},
};

export const HandlersContext = createContext<HandlersContextType>(
    initHandlersContext
);

const Calendar: FC = () => {
    // console.log(">>> Calendar");
    const dispatch = useDispatch();
    const calendarId = useSelector(getCalendarId);
    const calendarSettings = useSelector(getCalendarSettings);
    const workTimeStart = useSelector(getWorkTimeStart);
    const filters = useSelector(getFilters);
	const filterOptions = useSelector(getFilterOptions);
	const createEventMode = useSelector(getCreateEventMode);
	const selectedEvent = useSelector(getSelectedEvent);
	const isSelectedEvent = selectedEvent !== null;
	const shouldAutoRefresh = useSelector(getShouldAutoRefresh);
    const eventSort = useSelector(getEventSortFilter);
    const eventRefresh = useSelector(getEventRefresh);
    const eventSortRef = useRef(eventSort);

	const { t } = useTranslation();

    useEffect(() => {
        if (!calendarId) return;
        if (calendarId === '-1') {
            // dispatch(setCalendarDisplayTypeFilter('week'));
            const workTime = getStartEndTimeForMultipleCalendars(calendarSettings);
            dispatch(setWorkTimeStart(workTime.workTimeStart));
            dispatch(setWorkTimeEnd(workTime.workTimeEnd));
        } else {
            dispatch(setCalendarDisplayTypeFilter(calendarSettings[calendarId]?.startView ?? 'week'));
            dispatch(setWorkTimeStart(calendarSettings[calendarId]?.workTimeStart ?? {hours: 8, minutes: 0}));
            dispatch(setWorkTimeEnd(calendarSettings[calendarId]?.workTimeEnd ?? {hours: 22, minutes: 0}));
        }
    }, [calendarId, dispatch]);

	const prepareFilters = (fltrs: FiltersState) => {
		const [fromDate, toDate] = getStartEndDates(
			fltrs.pickedDate,
			fltrs.calendarDisplayType
		);
		// @ts-ignore
		const mappedTemplate = fltrs.template ? filterOptions.template[fltrs.template]?.billNr : undefined;
		return {
			from: format(fromDate, "yyyy-MM-dd"),
			to: format(toDate, "yyyy-MM-dd"),
			branchGroup:
				fltrs.branchGroup !== null ? fltrs.branchGroup : undefined,
			branch: fltrs.branch !== null ? fltrs.branch : undefined,
			templateGroupNr: fltrs.templateGroup !== null ? fltrs.templateGroup : undefined,
			// template: fltrs.template !== null ? fltrs.template : undefined,
			template: mappedTemplate,
			resourceGroup:
				fltrs.resourceGroup !== null
					? fltrs.resourceGroup
					: undefined,
			resource: fltrs.resource !== null ? fltrs.resource : undefined,
			specialResourceFilter: fltrs.specialResource !== null ? fltrs.specialResource : undefined,
			// unasignedResources: undefined, // TODO: add filter
			searchEntry: fltrs.search !== null ? fltrs.search : undefined,
			eventsWithoutOrder: fltrs.eventsWithoutOrder,
            suppliers: fltrs.suppliers !== null ? fltrs.suppliers : undefined,
		};
	}

    useEffect(() => {
        eventSortRef.current = eventSort;
    }, [eventSort]);

	const getEvents = (filter: any) => {
		getEventsFromAPI(filter)
			.then((res) => {
                const sortedEvents = sortEventsByKey(res.events, eventSortRef.current); 
				dispatch(setEvents(sortedEvents));
				dispatch(setBranchItems(res.branchItems));
				dispatch(setBranchItemsConflicts(findOverlappingEvents(res.events, res.branchItems)));
				dispatch(setLoading(false));
			})
			.catch((err) => console.log(err));
	}

    useEffect(() => {
		if (createEventMode) return;
		const filter = prepareFilters(filters);
		dispatch(setLoading(true));
		getEvents(filter);
		if (isSelectedEvent || !shouldAutoRefresh) return;
		const intervalId = setInterval(() => {
			getEvents(filter);
		}, 5000);

		return () => clearInterval(intervalId);
    }, [calendarId, filters, createEventMode, isSelectedEvent, shouldAutoRefresh, dispatch]);

    useEffect(() => {
        const filter = prepareFilters(filters);
		dispatch(setLoading(true));
		getEvents(filter);
    }, [eventRefresh]);

    useEffect(() => {
        const [start, end] = getStartEndDates(
            filters.pickedDate,
            filters.calendarDisplayType
        );
        start.setHours(workTimeStart.hours, workTimeStart.minutes, 0);
        dispatch(setCalendarStartDate(start));
        dispatch(setCalendarEndDate(end));
    }, [
        filters.pickedDate,
        filters.calendarDisplayType,
        workTimeStart,
        dispatch,
    ]);

    useEffect(() => {
        if (!filters.branch) return;

        getBranchResourcesFromAPI(filters.branch)
            .then((res) => {
                dispatch(setResourceItems(res));
            })
            .catch((err) => console.log(err));
    }, [filters.branch, dispatch]);

    return calendarId ? (
        // return (
        <>
            {filters.calendarDisplayType === "day" && <DayCalendar />}
            {filters.calendarDisplayType === "week" && <WeekCalendar />}
            {filters.calendarDisplayType === "month" && <MonthCalendar />}
            <EventEditTable />
        </>
        // )
    ) : (
        <>
			<div className="calendar-container">
				<div className="calendar-head">
					<p>
						<strong>{t("please_select_calendar")}</strong>
					</p>
				</div>
			</div>
        </>
    );
};

export default React.memo(Calendar);
