import React, { FC } from "react";
import {
    KeyboardDateTimePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { FormLabel, FormControl } from "@material-ui/core";
import { de } from 'date-fns/locale';

interface Props {
    label?: string;
    labelType?: "in" | "out";
    value: Date | null;
    disabled?: boolean;
    onChange: (value: MaterialUiPickersDate) => void;
}

const DateTimePickerField: FC<Props> = ({
    value,
    onChange,
    label,
    labelType = "in",
    disabled = false,
    ...props
}) => {
    return (
        <div className="field-container">
            <FormControl>
                <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                    <>
                        {labelType === "out" && <FormLabel>{label}</FormLabel>}
                        <KeyboardDateTimePicker
                            label={labelType === "in" ? label : ""}
                            variant="dialog"
                            inputVariant="outlined"
                            format="dd.MM.yyyy - HH:mm"
                            onChange={onChange}
                            value={value}
                            ampm={false}
                            disabled={disabled}
                        />
                    </>
                </MuiPickersUtilsProvider>
            </FormControl>
        </div>
    );
};

export default DateTimePickerField;
